import MediaSources from "../../media_sources";
import { parseUrlParams } from "../../../core/library/url";

const PROJECT = process.env.PROJECT || "";
const IsLoc = window.location.hostname.indexOf("localhost") + 1;

// const IsLoc = false;

export const school = {
  SHOW_LANGS: true,
  BASE_DOMAIN: "https://www.rt.com",
  APP_CLASS: "atel",
  PROJECT_NAME: "atel",
  PROJECT_PREFIX: "atel",
  LANGS: [
    {
      title: "English",
      domain: IsLoc
        ? "http://localhost:3000"
        : "https://adminst-school.rt.com/",
    },
    {
      title: "Russian",
      domain: IsLoc
        ? "http://ru.localhost:3000"
        : "https://adminst-school.rt.com/",
    },
  ],

  SOCKET: "",

  // settings for menu
  MEDIA: {
    // path for cdn content
    CDN: {
      IMAGES: IsLoc
        ? "http://localhost:9005/images"
        : "https://cdn.rt-school.online/rt_school/images",
      VIDEO: IsLoc
        ? "http://localhost:9005/videos"
        : "https://cdn.rt-school.online/rt_school/videos",
      PDF: IsLoc
        ? "http://localhost:9005/pdf/"
        : "https://cdn.rt-school.online/rt_school/pdf/",
      DOCS: IsLoc
        ? "http://localhost:9005/docs/"
        : "https://cdn.rt-school.online/rt_school/docs/",
    },
    // available copyright sources
    SOURCES: MediaSources,
    // available upload types
    UPLOAD_AVAILABLE: ["jpg", "gif", "png", "jpeg", "mp4", "mp3"],
    // available embedded sources
    EMBEDDED_SOURCES: [
      "youtube.com",
      "facebook.com",
      "youtu.be",
      `tvzvezda.ru`,
    ],
    EMEDDED_PREPARE: {
      "youtu.be": (path) => {
        let params = path.split("/");
        return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${
          params[params.length - 1]
        }" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      },
      "youtube.com": (path) => {
        let p = parseUrlParams(path);
        if (!p.v) return ``;
        return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      },
      "facebook.com": (url) => {
        return `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
          url
        )}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`;
      },
      "tvzvezda.ru": (url) => {
        return `<iframe src="${url}" width="500" height="315" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`;
      },
    },
    PREPARE: (path) => {
      if (path.indexOf("mp4/") + 1 > 0)
        return `https://cdn.rt-school.online${path.replace("https://files.vm.ru", "")}`;
      if (path.indexOf("vecherka") + 1 === 0)
        return `https://cdn.rt-school.online${path}`;
      return path;
    },
  },
  WORKFLOW: {
    SHOW_SEARCH: true,
    SHOW_LINK: true,
    PREPARE_LINK: (item) => {
      return `link`;
    },
    STATUSES: ["DRAFT", "READY", "ARCHIVE", "PUBLISH"],
    STATUSES_COLOR: {
      DRAFT: "gray",
      READY: "volcano",
      ARCHIVE: "purple",
      PUBLISH: "green",
    },
    DEFAULT: "DRAFT",
    LOCK_FOR: [
      "article",
      "news",
      "infographic",
      "episode",
      "gallery",
      "meaning",
    ],
    WORKFLOW_FOR: ["episode", "article", "news", "gallery", "meaning"],
  },
  WIDGETS: {
    TYPES: ["News", "Banner", "Study", "Events"],
    VIEWS: [
      {label: 'RT School', view: 'RT_SCHOOL.html'},
      {label: 'Artel', view: 'ARTEL_DOC.html'},
      {label: 'RT Regions', view: 'RT_REGION.html'},
      {label: 'События', view: 'EVENTS_MAINPAGE.html'},
      {label: 'События произошедшие', view: 'EVENTS_MAINPAGE_PAST.html'},
      {label: 'Новости', view: 'NEWS_MAINPAGE.html'},
      {label: 'Наборы', view: 'STUDY_MAINPAGE.html'},
    ]
  },
  ELASTIC: {
    API: IsLoc
      ? "http://localhost:9005/api/backend/v1/elastic"
      : "https://adminst-school.rt.com/api/backend/v1/elastic",
  },
  PROJECT: {
    // project name
    NAME: PROJECT,
    // project default layout
    LAYOUT: "default",
    // project api path
    API: IsLoc
      ? `http://localhost:9005/api/backend/v1`
      : `https://adminst-school.rt.com/api/backend/v1`,
    ELASTIC_API: IsLoc
      ? `http://localhost:9005/elastic`
      : `https://adminst-school.rt.com/elastic`,
  },

  UPLOAD: {
    path: IsLoc
      ? "http://localhost:9005/api/backend/v1/media"
      : "https://adminst-school.rt.com/api/backend/v1/media",
  },
  // oauth configuration
  OAUTH: {
    // url for oauth domain
    URL: `https://auth.rt.com/`,
    // current project domain
    PROJECT_DOMAIN: IsLoc ? `localhost` : "adminst-school.rt.com",
    // current project url path
    PROJECT_PATH: IsLoc
      ? `http://localhost:3000`
      : "https://adminst-school.rt.com/",
    // PROJECT_PATH: IsLoc ? `http://localhost:3000` : `https://admin.arteldoc.com`,
    // path for oauth api
    API: `https://auth.rt.com/auth`,
    // path for oauth users
    OAUTH_USER_PATH: `https://auth.rt.com/users/`,
    // prefix for authorization tokens
    PREFIX: "oauth_",
    HEADER: "x-access-token",
  },

  VIEWS: {
    documents: {
      article: "default_view_vm",
      author: "authors_view",
      fauthor: "authors_view",
      category: "category_view",
      fcategory: "category_view",
      tag: "tag_view",
      bestworks_years: "tag_view",
      courses: "category_view",
      trend: "trend_view",
      page: "page_view",
      show: "show_view",
      newspaper: "newspaper_view",
      rnewspaper: "rnewspaper_view",
      schedule: "schedule_view",
      arates: "arates_view",
      aviews: "aviews_view",
      polls: "poll",
      mcat: "anons_view",
      covid: "covid",
    },
    documents_add: {
      courses: "course",
      article: "add_article_vm",
      bestworks: 'AddBestWorks',
      trend: "add_trend",
      news: "add_news",
      films: "add_films",
      author: "add_author",
      fauthor: "add_author",
      category: "add_category",
      fcategory: "add_category",
      tag: "add_tag",
      bestworks_years: "add_tag",
      banner: "add_banner",
      study: "Study",
      page: "add_page",
      newspaper: "add_newspaper",
      rnewspaper: "add_rnewspaper",
      episode: "add_episode",
      battle: "add_battle",
      schedule: "add_schedule",
      arates: "arate_add",
      aviews: "aviews_add",
      infographic: "infographics_add",
      polls: "poll_add",
      mcat: "anons_add",
      covid: "covid",
      afisha: "afisha_vm",
      regionmat: "region_material",
      mgallery: "mgallery",
      stories: "stories",
      masterclass: "masterclass",
      lessons: "lessons",
      comments: "AddComments",
      vcomments: "AddCommentsVideo",
      projects: "Projects",
      events: "Events",
      timetable: "Timetable",
    },
  },
  MENU: [
    {
      title: "DASHBOARD",
      path: "/",
      component: "dashboard_index",
      icon: "dashboard",
    },
    {
      title: "DOCUMENTS",
      path: "/documents/:type",
      component: "default_document",
      subroutes: [
        {
          path: "/documents/:type/:id",
          component: "add_documents",
        },
        {
          path: "/documents/:type/:id/preview",
          component: "preview",
        },
        {
          path: "/documents/:type/create",
          component: "add_documents",
        },
        {
          path: "/archive",
          component: "archive",
        },
        {
          path: "/documents/episode/:id/chat",
          component: "episode_chat",
        },
        {
          path: "/mediagallery",
          component: "media_gallery",
        },
      ],
      icon: "container",
      submenu: [
        {
          title: "News",
          path: "/documents/news",
          icon: "ordered-list",
        },
        {
          title: "MEDIA_GALLERY_LIST",
          path: "/mediagallery",
          icon: "picture",
        },
        {
          title: "BEST_WORKS",
          path: "/documents/bestworks",
          icon: "ordered-list",
        },
        {
          title: "BEST_WORKS_YEARS",
          path: "/documents/bestworks_years",
          icon: "ordered-list",
        },
      ],
    },
    {
      title: "STUDY",
      icon: "container",
      submenu: [
        {
          title: "PROJECTS",
          path: "/documents/projects",
          icon: "ordered-list",
        },
        {
          title: "STUDY",
          path: "/documents/study",
          icon: "database",
        },
      ],
    },
    {
      title: "EVENTS",
      icon: "container",
      submenu: [
        {
          title: "EVENTS",
          path: "/documents/events",
          icon: "ordered-list",
        },
        {
          title: "EVENTS_DATES",
          path: "/documents/timetable",
          icon: "database",
        },
        {
          title: "Gallery",
          path: "/documents/gallery",
          icon: "appstore",
        },
        {
          title: "SPEAKERS",
          path: "/documents/authors",
          icon: "user",
        },
      ],
    },
    {
      title: "BANNERS",
      path: "/documents/banner",
      icon: "layout",
    },
    {
      title: "WIDGETS",
      path: "/widgets",
      icon: "appstore",
      component: "widget",
      submenu: [
        {
          title: "WIDGETS_EDITOR",
          path: "/widgets",
          icon: "appstore",
          component: "widget",
        }
      ],
      subroutes: [
        {
          path: "/widgets/:id",
          component: "widget_edit",
        },
      ],
    },
    {
      title: "PAGES",
      path: "/documents/page",
      icon: "file-text",
    },
  ],
};

export default school;
