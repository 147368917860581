import { MergeObjects } from "./../core/library/objects";
import MediaSources from "./media_sources";
import * as Projects from "./projects";
import { parseUrlParams } from "../core/library/url";
import PushRegions from "./regions";

const PROJECT = process.env.PROJECT || "school";
console.debug("ENV:", PROJECT);

let Config = {
  LOCALE: "ru",
  PROJECT_PREFIX: "test",
  // project config
  PROJECT: {
    // project name
    NAME: PROJECT,
    // project default layout
    LAYOUT: "default",
    // project api path
    API: `http://localhost:9005/api/backend/v1`,
  },

  // oauth configuration
  OAUTH: {
    // url for oauth domain
    URL: `https://auth.rt.com/`,
    // current project domain
    PROJECT_DOMAIN: `localhost:3000`,
    // current project url path
    PROJECT_PATH: `http://localhost:3000`,
    // path for oauth api
    API: ``,
    // path for oauth users
    OAUTH_USER_PATH: ``,
    // prefix for authorization tokens
    PREFIX: "oauth_",
    HEADER: "x-access-token",
  },
  // default date format
  DATE_FORMAT: "DD-MM-YYYY HH:mm",
  // configuration for slug plugin
  SLUG: {
    delimiter: "-",
    prefix: "",
  },

  // configuration for media
  MEDIA: {
    // path for cdn content
    CDN: {
      IMAGES: "https://cdni.rbth.com/rbthmedia/images",
      VIDEO: "https://cdni.rbth.com/rbthmedia/video",
    },
    // available copyright sources
    SOURCES: MediaSources,
    // available upload types
    UPLOAD_AVAILABLE: ["jpg", "gif", "png", "jpeg", "mp4", "mp3"],
    // available embedded sources
    EMBEDDED_SOURCES: ["youtube.com", "facebook.com", "youtu.be"],
    EMEDDED_PREPARE: {
      "youtu.be": (path) => {
        let params = path.split("/");
        return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${
          params[params.length - 1]
        }" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      },
      "youtube.com": (path) => {
        let p = parseUrlParams(path);
        if (!p.v) return ``;
        return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      },
      "facebook.com": (url) => {
        return `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
          url
        )}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`;
      },
    },
  },

  DOCUMENTS: {
    STATUS: ["published", "unpublished"],
    MOVE_TYPES: ["News", "Article", "Meaning", "Embargo"],
  },
  EDITOR: {
    KEY: "clzzivqs4p1bb1eoei67nmbzwwzio5uv0w69ew52cc8ex5s7",
    SETTINGS: {
      language: "ru",
    },
  },
  MAPS: {
    KEY: "e70f3b03-0677-45b8-911f-830daf383e37",
  },
  ELASTIC: {
    API: "http://localhost:8009/elastic",
  },
  MEDIA_DATABASE_TYPES: ["Image", "Video", "ExternalVideo", "Code"],
  MEDIA_AVAILABLE_TYPES: ".jpg,.gif,.png,.jpeg,.mp4,.mp3",
  MEDIA_AVAILABLE_SOURCES: ["youtube", "vimeo", "facebook"],
  WIDGETS: {
    TYPES: [
      "Article",
      "News",
      "Episode",
      "Infographic",
      "Meaning",
      "Trend",
      "Banner",
      "Polls",
    ],
  },
  REGIONS: PushRegions,
  CROPPER: {
    WIDTH: 1200,
    HEIGHT: 675,
  },
};

if (Projects[PROJECT]) {
  Config = MergeObjects(Config, Projects[PROJECT]);
}

window.APP_CONFIG = {
  PROJECT: Config.PROJECT,
  MEDIA: Config.MEDIA,
};

export default Config;
